<template>
  <div>
    <v-lazy v-if="userStore.isHuman" v-model="model" :height="height" :max-height="maxHeight"
      :max-width="maxWidth" :min-height="computedMinHeight" :min-width="minWidth" :options="options" :tag="tag"
      :transition="transition" :width="width" @update:model-value="emit('update:modelValue', $event)">
      <div>
        <slot />
      </div>
    </v-lazy>
    <div v-else-if="forceRenderForBot">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type TransitionProps } from 'vue';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';

const props = withDefaults(defineProps<{
  height?: string | number
  maxHeight?: string | number
  maxWidth?: string | number
  minHeight?: string | number
  minWidth?: string | number
  // modelValue?: boolean
  options?: IntersectionObserverInit
  tag?: string
  transition?: string | boolean | (TransitionProps & { component?: Component })
  width?: string | number
  /**
   * 봇인 경우 무조건 렌더링되어 크롤링할 수 있게 해주는 옵션. 기본값은 false.
   */
  forceRenderForBot?: boolean
}>(), {
  forceRenderForBot: () => false
})

const emit = defineEmits<{
  /**
   * 사용한 페이지에서 dyLazy가 생성될 때 최초에는 undefined를 보냄(false가 아님!) 그러다가 해당 요소가 보이는 순간 true 이벤트를 쏨
   */
  (e: 'update:modelValue', v?: boolean): void
}>()

const userStore = useDYUserStore()
const model = ref(false)

const computedMinHeight = computed(() => model.value ? undefined : (props.minHeight ?? 300))
</script>